.spinner {
  border: 8px solid #f3f3f3; /* Açık gri */
  border-top: 8px solid #4caf50; /* Yeşil */
  border-radius: 50%;
  width: 40px; /* Boyut */
  height: 40px; /* Boyut */
  animation: spin 1s linear infinite; /* Döngüsel animasyon */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
} 